import axios from 'axios'

const state = {
  campaigns: [],
  contacts: null,
  selectedCampaign: null,
  campaignStats: {
    loading: false,
    data: null,
    error: null
  }
}

const actions = {

  CREATE_REPLY_IO_CAMPAIGN ({ commit, dispatch, state }, { startupId, searchId }) {
    dispatch('LOADER_START')
    return new Promise((resolve, reject) => {
      axios.post(`${import.meta.env.VITE_API_URL}startups/${startupId}/searches/${searchId}/campaigns/`)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
        .finally(() => {
          dispatch('LOADER_STOP')
        })
    })
  },

  LOAD_REPLY_IO_CAMPAIGNS ({ commit, state }, startupId) {
    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}campaign_list/${startupId}`)
        .then((response) => {
          commit('SET_CAMPAIGNS', response.data)
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  LOAD_OUTREACH_CONTACTS ({ commit, dispatch, state }, { startupId, searchId, params }) {
    dispatch('LOADER_START')
    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}startups/${startupId}/searches/${searchId}/outreach_contacts/`, { params })
        .then((response) => {
          commit('SET_OUTREACH_CONTACTS', response.data)
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
        .finally(() => {
          dispatch('LOADER_STOP')
        })
    })
  },

  START_OUTREACH ({ commit, dispatch, state }, { startupId, searchId, campaignId, campaignName, customFields }) {
    dispatch('LOADER_START')
    return new Promise((resolve, reject) => {
      const payload = {
        campaign_id: campaignId,
        campaign_name: campaignName,
        custom_fields: customFields
      }
      axios.post(`${import.meta.env.VITE_API_URL}startups/${startupId}/searches/${searchId}/outreach`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
        .finally(() => {
          dispatch('LOADER_STOP')
        })
    })
  },

  LOAD_REPLY_IO_CAMPAIGN ({ commit, dispatch, state }, { startupId, searchId }) {
    dispatch('LOADER_START')
    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}startups/${startupId}/searches/${searchId}/campaign/`)
        .then((response) => {
          commit('SET_SELECTED_CAMPAIGN', response.data)
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
        .finally(() => {
          dispatch('LOADER_STOP')
        })
    })
  },

  START_CAMPAIGN({ commit }, { startupId, searchId }) {
    return new Promise((resolve, reject) => {
      axios.post(`${import.meta.env.VITE_API_URL}startups/${startupId}/searches/${searchId}/start_campaign/`)
        .then(response => {
          commit('UPDATE_CAMPAIGN_STATUS', 'active');
          resolve(response.data);
        })
        .catch(error => reject(error));
    });
  },

  PAUSE_CAMPAIGN({ commit }, { startupId, searchId }) {
    return new Promise((resolve, reject) => {
      axios.post(`${import.meta.env.VITE_API_URL}startups/${startupId}/searches/${searchId}/pause_campaign/`)
        .then(response => {
          commit('UPDATE_CAMPAIGN_STATUS', 'paused');
          resolve(response.data);
        })
        .catch(error => reject(error));
    });
  },

  UPDATE_CAMPAIGN_STEP({ commit, dispatch }, { startupId, searchId, stepId, payload }) {
    dispatch('LOADER_START')
    return new Promise((resolve, reject) => {
      axios.patch(
        `${import.meta.env.VITE_API_URL}startups/${startupId}/searches/${searchId}/campaigns/${stepId}/`,
        payload
      )
        .then((response) => {
          commit('UPDATE_CAMPAIGN_TEMPLATE', { stepId, payload })
          resolve(response)
        })
        .catch(error => {
          console.error('Failed to update campaign step:', error)
          reject(error)
        })
        .finally(() => {
          dispatch('LOADER_STOP')
        })
    })
  },

  async FETCH_CAMPAIGN_STATS({ commit }, { startupId, campaignId, startDate, endDate }) {
    commit('SET_CAMPAIGN_STATS_LOADING', true)
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_API_URL}startups/${startupId}/searches/campaigns/${campaignId}/stats/`,
        {
          params: {
            start_date: startDate,
            end_date: endDate
          }
        }
      )
      commit('SET_CAMPAIGN_STATS', response.data)
      return response.data
    } catch (error) {
      const errorMessage = error.response?.data?.error || 'Error fetching campaign stats'
      commit('SET_CAMPAIGN_STATS_ERROR', errorMessage)
      throw error
    } finally {
      commit('SET_CAMPAIGN_STATS_LOADING', false)
    }
  }
}

const getters = {}

const mutations = {
  SET_CAMPAIGNS (state, payload) {
    state.campaigns = payload
  },
  SET_OUTREACH_CONTACTS (state, payload) {
    state.contacts = payload
  },
  CLEAR_OUTREACH (state) {
    state.contacts = null
  },
  SET_SELECTED_CAMPAIGN (state, payload) {
    state.selectedCampaign = payload
  },
  UPDATE_CAMPAIGN_STATUS(state, status) {
    if (state.selectedCampaign) {
      state.selectedCampaign.status = status;
    }
  },
  UPDATE_CAMPAIGN_TEMPLATE(state, { stepId, payload }) {
    if (state.selectedCampaign && state.selectedCampaign.steps) {
      const step = state.selectedCampaign.steps.find(s => s.id === parseInt(stepId))
      if (step && step.templates && step.templates.length > 0) {
        // Update the first template in the step
        Object.assign(step.templates[0], {
          subject: payload.templates[0].subject,
          body: payload.templates[0].body
        })
        // Update the minutes count if provided
        if (payload.inMinutesCount !== undefined) {
          step.inMinutesCount = payload.inMinutesCount
        }
      }
    }
  },
  SET_CAMPAIGN_STATS_LOADING(state, loading) {
    state.campaignStats.loading = loading
  },
  SET_CAMPAIGN_STATS(state, data) {
    state.campaignStats.data = data
    state.campaignStats.error = null
  },
  SET_CAMPAIGN_STATS_ERROR(state, error) {
    state.campaignStats.error = error
    state.campaignStats.data = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
