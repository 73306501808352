import { mapState } from 'vuex'
import Swal from 'sweetalert2'

export default {
  name: 'searchMixin',

  data() {
    return {
      setupDone: false,
      page: 1,
      perPage: 50,
      filtersChanged: false,
      form: [],
      matchForm: [],
      breakdownFieldOptions: [
        {
          id: 'investment_countries',
          label: 'Investment Countries',
        },
        {
          id: 'investor_region',
          label: 'Investor Region',
        },
        {
          id: 'investor_type',
          label: 'Investor Type',
        }
      ],
      localBreakdownField: null,
      localBreakdownTier: null,
      breakdownGroups: [],
      breakdownTierOptions: [
        {
          id: 1,
          label: 'Tier 1 (> 75%)',
        },
        {
          id: 2,
          label: 'Tier 2 (60-75%)',
        },
        {
          id: 3,
          label: 'Tier 3 (< 60%)',
        },
      ],
      default_exclude: true,
      hide_ring_fence: true,
      hide_undeliverable_contact: true,
      searchTimer: null,
      tagOptions: [],
      searchName: '',
    }
  },

  computed: {
    ...mapState({
      isLoading: state => state.loader.active,
      startup: state => state.startups.current,
      companies: state => state.investors.companies,
      contacts: state => state.investors.contacts,
      searchFields: state => state.investors.searchFields,
      searchForm: state => state.investors.searchForm,
      searchOptions: state => state.investors.searchOptions,
      breakdownOptions: state => state.investors.breakdownOptions,
      matchFields: state => state.investors.matchFields,
      matchGroup: state => state.investors.matchGroup,
      optionsInvestorTypes: state => state.investors.investorTypes,
      optionsInvestmentStages: state => state.investors.investmentStages,
      optionsCities: state => state.investors.cities,
      optionsCountries: state => state.investors.countries,
      optionsRegions: state => state.investors.regions,
      isCareerMatchesSearch: state => state.investors.isCareerMatchesSearch,
      isSameYearCareerMatchesSearch: state => state.investors.isSameYearCareerMatchesSearch,
      isBestMatchesSearch: state => state.investors.isBestMatchesSearch,
      breakdownField: state => state.investors.breakdownField,
      currentSearch: state => state.investors.currentSearch,
      breakdownTier: state => state.investors.breakdownTier,
      activeSearchCount: state => state.investors.activeSearchCount,
    })
  },

  mounted() {
    window.scrollTo(0, 0)

    Promise.all([
      this.$store.dispatch('LOAD_STARTUP', this.startup.id),
      this.$store.dispatch('LOAD_SEARCH_OPTIONS')
    ]).then(() => {
      if (!this.startup) {
        return
      }

      // this.breakdownField = this.breakdownFieldOptions.find((f) => f.id === this.breakdownOptions.field_name) || this.breakdownFieldOptions[0]
      this.localBreakdownField = this.breakdownField
      this.breakdownGroups = this.breakdownOptions?.custom_groups
      this.localBreakdownTier = this.breakdownTier

      if (!this.searchName && this.matchGroup) {
        this.searchName = `${this.startup.name}: ${this.matchGroup}`
      }

      // this will trigger doSearch() and load content
      this.form = this.searchForm
      if(this.searchOptions) {
        this.default_exclude = this.searchOptions.default_exclude ?? this.default_exclude
        this.hide_ring_fence = this.searchOptions.hide_ring_fence ?? this.hide_ring_fence
        this.hide_undeliverable_contact = this.searchOptions.hide_undeliverable_contact ?? this.hide_undeliverable_contact
      }

      // fill tags initially
      this.searchTags('', () => { })

      if (this.$route.query.match === 'true') {
        Promise.all([
          this.$store.dispatch('LOAD_MATCH_FIELDS', { startupId: this.startup.id }),
          this.$store.dispatch('LOAD_SEARCHES', { startupId: this.startup.id }),
          this.$store.commit('SET_CURRENT_SEARCH', null),
        ]).then(() => {
          this.addMatchFilters()
          // this.localBreakdownField = null
          this.localBreakdownTier = null
          this.doSearch()
        })
      } else if (this.$route.query.search === 'true') {
        if (!this.currentSearch?.is_best_match && !this.currentSearch?.is_career_match) {
          this.addMatchFilters(this.currentSearch?.selected_match_group)
        }
        this.localBreakdownTier = this.currentSearch?.tier
        this.doSearch()
      }

      if (this.form.length === 0) {
        this.addFilter()
      }

      setTimeout(() => {
        this.setupDone = true
        if (!this.companies || !this.contacts) {
          this.doSearch()
        }
      }, 1)
    })
  },

  beforeUnmount() {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer)
    }
  },

  methods: {
    resetSharedState() {
      this.$store.commit('RESET_ACTIVE_SEARCH_COUNT')
      this.$store.commit('SET_COMPANIES', null)
      this.$store.commit('SET_CONTACTS', null)
    },

    searchTags(query, loading) {
      loading(true)
      this.$store.dispatch('SEARCH_TAGS', query)
        .then((response) => {
          this.tagOptions = response.data
        })
        .finally(() => {
          loading(false)
        })
    },

    searchCities(item, query, loading) {
      loading(true)
      const ids = item.value
      this.$store.dispatch('SEARCH_CITIES', { ids, query })
        .finally(() => {
          loading(false)
        })
    },

    addMatchFilters(groupName) {
      this.form = []

      if (this.matchFields?.length === 0 && this.localBreakdownField !== null) {
        Swal.fire({
          title: 'No Match Fields',
          text: `Please add values for ${this.localBreakdownField.label} in the profile of ${this.current.name}`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Add Now',
          cancelButtonText: 'I\'ll do it later',
          reverseButtons: true
        }).then((result) => {
          this.localBreakdownField = null
          if (result.isConfirmed) {
            this.$router.push(`/startups/${this.current.id}/edit`)
          }
        })
        return
      }

      if (!groupName) {
        if (this.matchGroup && this.matchFields.find((g) => g.name === this.matchGroup)) {
          groupName = this.matchGroup
        } else {
          groupName = this.matchFields[0].name
        }
      }

      if (groupName.toLowerCase().includes('same year')) {
        this.$store.commit('SET_IS_CAREER_MATCHES_SEARCH', false)
        this.$store.commit('SET_IS_SAME_YEAR_CAREER_SEARCH', true)
      } else if (groupName.toLowerCase().includes('career matches')) {
        this.$store.commit('SET_IS_CAREER_MATCHES_SEARCH', true)
        this.$store.commit('SET_IS_SAME_YEAR_CAREER_SEARCH', false)
      } else {
        this.$store.commit('SET_IS_CAREER_MATCHES_SEARCH', false)
        this.$store.commit('SET_IS_SAME_YEAR_CAREER_SEARCH', false)
      }
      this.$store.commit('SET_IS_BEST_MATCHES_SEARCH', groupName === 'Best Matches')

      const matchFields = this.matchFields.find((g) => g.name === groupName)
      if (!matchFields) {
        return
      }

      this.searchName = `${this.startup.name}: ${groupName}`

      this.$store.commit('SET_MATCH_GROUP', groupName)

      let fieldGroups = [];

      if (this.currentSearch?.search_queries_attributes) {
        fieldGroups = JSON.parse(this.currentSearch.search_queries_attributes);
      } else {
        fieldGroups = matchFields.filter;
      }

      for (const fieldGroup of fieldGroups) {
        var group = {
          fields: [],
          operator: fieldGroup.operator
        }
        for (const field of fieldGroup.fields) {
          const fieldDef = this.searchFields.find((f) => f.field_name === field.field_name)
          const condition = fieldDef.conditions.find((c) => (c.id === field.condition && (!field.exclude || c.exclude === field.exclude)))
          group.fields.push({
            field_name: field.field_name,
            field: fieldDef,
            exclude: field.exclude,
            condition: condition,
            value: field.value
          })
          if (fieldDef.field_type === 'city') {
            this.searchCities({
              value: field.value
            }, '', () => { })
          }
        }
        this.form.push(group)
      }
      this.matchForm = JSON.parse(JSON.stringify(this.form))
    },

    addFilter() {
      this.form.push({
        fields: [
          {
            field_name: null,
            condition: null,
            value: null
          },
        ],
        operator: "AND"
      })
    },

    addField(group) {
      group.fields.push({
        field_name: null,
        condition: null,
        value: null
      })
    },

    onFieldChange(item) {
      if (item.field && item.field.conditions && item.field.conditions.length > 0) {
        item.condition = item.field.conditions[0]
      }
    },

    onConditionOpen(item) {
      item.condition = null
    },

    deleteField(group, index, groupIndex) {
      group.fields.splice(index, 1)
      if (group.fields.length === 0) {
        this.form.splice(groupIndex, 1)
      }
    },

    clearFilters() {
      // Store the current form to check for match fields
      const previousForm = this.form;

      // Clear the form
      this.form = [];

      // Get default fields from match fields if they exist
      if (this.matchFields && this.matchFields.length > 0) {
        const defaultGroup = {
          fields: [],
          operator: "AND"
        };

        // Create a Set to track which fields we've already added
        const addedFields = new Set();

        this.matchFields.forEach(matchField => {
          matchField.filter.forEach(fieldGroup => {
            fieldGroup.fields.forEach(field => {
              // Check if the field is one of the default fields to preserve and hasn't been added yet
              if (['industry_sector_tags__name', 'investment_stages', 'investment_countries', 'investor_city', 'investor_type'].includes(field.field_name)
                && !addedFields.has(field.field_name)) {

                // Mark this field as processed
                addedFields.add(field.field_name);
                // Get the field definition
                const fieldDef = this.searchFields.find(f => f.field_name === field.field_name);
                if (fieldDef) {
                  // Always create a new field with default values
                  defaultGroup.fields.push({
                    field_name: field.field_name,
                    field: fieldDef,
                    condition: fieldDef.conditions[0],
                    value: null,  // Always set value to null when clearing
                    exclude: false
                  });
                }
              }
            });
          });
        });

        // Only add the group if we found preserved fields
        if (defaultGroup.fields.length > 0) {
          this.form.push(defaultGroup);
        }
      }

      // Add empty filter group if no fields were preserved
      if (this.form.length === 0) {
        this.addFilter();
      }

      // Call doSearch at the end
      this.doSearch();
    },

    onBreakdownFieldChange() {
      this.breakdownGroups = []
      this.updateBreakdown()
    },

    onBreakdownTierChange() {
      // this.doSearch()
    },

    addBreakdownGroup() {
      this.breakdownGroups.push({
        value: []
      })
    },

    deleteBreakdownGroup(group, index) {
      this.breakdownGroups.splice(index, 1)
      this.updateBreakdown()
    },

    onBreakdownGroupsChange() {
      this.updateBreakdown()
    },

    updateBreakdown() {
      var groups = []
      var isDefault = true
      if (this.breakdownGroups.length) {
        isDefault = false
        for (const group of this.breakdownGroups) {
          var customGroup = {}
          customGroup[this.localBreakdownField.id] = group.value
          groups.push(customGroup)
        }
      }
      const breakdown = this.localBreakdownField ? {
        field_name: this.localBreakdownField.id,
        default: isDefault,
        custom_groups: groups
      } : null;
      this.$store.dispatch('LOAD_MATCH_FIELDS', { startupId: this.startup.id, breakdown })
        .then(() => {
          this.addMatchFilters()
          // this.doSearch()
        }
        )
    },

    makeQuery() {
      var q = []
      for (const group of this.form) {
        const fields = group.fields
          .filter((f) => {
            return f.field && f.condition && f.value
          })
          .map((f) => {
            return {
              field_name: f.field.field_name,
              condition: f.condition.id,
              exclude: !!f.condition.exclude,
              value: Array.isArray(f.value) ? f.value : [f.value],
            }
          })
        q.push({
          fields: fields,
          operator: group.operator
        })
      }
      return q
    },

    doSearch(args = {}) {
      if (!this.startup) { return }
      this.filtersChanged = false

      const options = {
        default_exclude: this.default_exclude,
        hide_ring_fence: this.hide_ring_fence,
        hide_undeliverable_contact: this.hide_undeliverable_contact,
        tier: this.localBreakdownTier || null,
        update_insights: args.update_insights || false,
        update_contact_insights: args.update_contact_insights || false
      }

      const q = this.makeQuery()
      const form = {
        query: q,
        ...options,
        has_career_match: this.isCareerMatchesSearch,
        is_best_match_search: this.isBestMatchesSearch,
        is_same_year_career_match: this.isSameYearCareerMatchesSearch,
      }
      const offset = (this.page - 1) * this.perPage
      const params = {
        startup_id: this.startup.id,
        offset
      }

      this.$store.commit('SET_SEARCH_FORM', this.form)
      this.$store.commit('SET_SEARCH_OPTIONS', options)

      return Promise.all([
        this.$store.dispatch('SEARCH', { form, params, startupId: this.startup.id }),
      ]).then(() => {
        this.filtersChanged = false
      })
    },
  },

  watch: {
    'form': {
      handler: function (val, oldVal) {
        if (!this.setupDone) {
          return
        }

        this.filtersChanged = true

        if (this.searchTimer) {
          clearTimeout(this.searchTimer)
        }
        this.searchTimer = setTimeout(() => {
          // this.doSearch()
        }, 500)

        // if (JSON.stringify(this.matchForm) !== JSON.stringify(this.form)) {
        //   this.$store.commit('SET_MATCH_GROUP', null)
        // }

        this.page = 1
      },
      deep: true
    },

    'default_exclude': {
      handler: function (val, oldVal) {
        if (!this.setupDone) {
          return
        }
        this.filtersChanged = true
        // this.doSearch()
      }
    },

    'hide_ring_fence': {
      handler: function (val, oldVal) {
        console.log(val, oldVal, this.setupDone)
        if (!this.setupDone) {
          return
        }
        this.filtersChanged = true
        // this.doSearch()
      }
    },

    'hide_undeliverable_contact': {
      handler: function (val, oldVal) {
        if (!this.setupDone) {
          return
        }
        this.filtersChanged = true
      }
    },

    'localBreakdownTier': {
      handler: function (val, oldVal) {
        if (!this.setupDone) {
          return
        }
        this.filtersChanged = true
        this.$store.commit('SET_BREAKDOWN_TIER', val)
      }
    },

    'localBreakdownField': {
      handler: function (val, oldVal) {
        if (!this.setupDone) {
          return
        }
        this.filtersChanged = true
        this.onBreakdownFieldChange()
        this.$store.commit("SET_BREAKDOWN_FIELD", val);
      }
    },
  }
}