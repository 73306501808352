import axios from 'axios'
import Swal from 'sweetalert2'

const state = {
  startups: null,
  current: null,
  form: null,
  optionsSmartMoneyTags: [],
  optionsExitStrategy: [],
  optionsRunwayDuration: []
}

const actions = {
  LOAD_STARTUPS ({ commit, dispatch, state }, params) {
    dispatch('LOADER_START')
    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}startups/`, { params })
        .then((response) => {
          commit('SET_STARTUPS', response.data)
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
        .finally(() => {
          dispatch('LOADER_STOP')
        })
    })
  },

  LOAD_STARTUP ({ commit, dispatch, state }, startupId) {
    dispatch('LOADER_START')
    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}startups/${startupId}/`)
        .then((response) => {
          commit('SET_CURRENT_STARTUP', response.data)
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
        .finally(() => {
          dispatch('LOADER_STOP')
        })
    })
  },

  RELOAD_CURRENT_STARTUP ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      if (state.current) {
        axios.get(`${import.meta.env.VITE_API_URL}startups/${state.current.id}/`)
          .then((response) => {
            commit('SET_CURRENT_STARTUP', response.data)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      } else {
        resolve()
      }
    })
  },

  UPDATE_STARTUP ({ commit, dispatch, state }, payload) {
    dispatch('LOADER_START')
    console.log(payload)
    return new Promise((resolve, reject) => {
      // send files separately
      const fileData = new FormData()
      const fileKeys = ["current_cap_table", "pitch_deck_file", "executive_summary_file"]
      for (const [key, value] of Object.entries(payload)) {
        if (fileKeys.includes(key)) {
          if (value && typeof value === 'object') {
            fileData.append(key, value, value.name)
          }
          delete payload[key]
        }
      }
      // if fileData not empty then send it first
      if (!fileData.entries().next().done) {
        axios.patch(`${import.meta.env.VITE_API_URL}startups/${payload.id}/`, fileData)
          .then((response) => {
            if (Object.keys(payload).length === 0) {
              resolve(response)
              return
            }
            axios.patch(`${import.meta.env.VITE_API_URL}startups/${payload.id}/`, payload)
              .then((response) => {
                resolve(response)
              })
              .catch(error => {
                console.log(error)
                reject(error)
              })
              .finally(() => {
                dispatch('LOADER_STOP')
              })
          })
          .catch(error => {
            console.log(error)
            reject(error)
            dispatch('LOADER_STOP')
          })

        return
      }
      
      axios.patch(`${import.meta.env.VITE_API_URL}startups/${payload.id}/`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
        .finally(() => {
          dispatch('LOADER_STOP')
        })
    })
  },

  CREATE_STARTUP ({ commit, dispatch, state }, payload) {
    dispatch('LOADER_START')
    return new Promise((resolve, reject) => {
      commit('SET_FORM', payload)

      if (!payload || typeof payload !== 'object' || Object.keys(payload).length === 0) {
        console.error('Payload is empty or not an object')
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please fill all the required fields!",
        })
        reject(new Error('Invalid payload'))
        return
      }

      const fieldMapping = {
        name: "Company Name",
        description: "How would you describe your company in 1-2 paragraphs?",
        industry_sector_tags: "What are the industries & sectors keywords in which your company operates in. Please add as many as you can (eg. gaming, tech, music, real estate, healthcare)",
        preferred_investor_region: "Preferred Investor Region",
        preferred_investor_type: "Preferred Investor Type",
        company_location_city: "In which city is your company headquartered?",
        current_investment_stage: "Current Investment Stage",
        registered_country: "In which country is your company registered?",
        team_location: "What are the countries, where your team members are located in?",
        preferred_investor_location: "Preferred Investor location",
        expansion_countries: "In which countries are you looking to expand within the next 12-24 months?",
        current_investors_location: "What are the locations of your current investors?",
        target_raise: "How much is total target raise for this round?",
        current_valuation: "Current Valuation",
        minimum_ticket_size: "What is the minimum ticket size you are accepting for this round?",
        current_cap_table: "Current Cap Table (CSV)"
      }

      const requiredFields = ['name', 'description', 'industry_sector_tags', 'preferred_investor_region', 'preferred_investor_type', 'company_location_city', 'current_investment_stage', 'preferred_investor_region', 'preferred_investor_type', 'company_location_city', 'current_investment_stage', 'registered_country', 'team_location', 'preferred_investor_location', 'expansion_countries', 'current_investors_location', 'target_raise', 'current_valuation', 'minimum_ticket_size']
      const requiredFileFields = ['current_cap_table']; // Add more required file fields here as needed
      const fileFields = [...requiredFileFields, "pitch_deck_file", "executive_summary_file"]

      // // Check for required fields
      // for (const field of requiredFields) {
      //   if (!payload[field]) {
      //     console.error(`Missing required field: ${field}`)
      //     Swal.fire({
      //       icon: "error",
      //       title: "Oops...",
      //       text: `Please answer the question: ${fieldMapping[field]}`,
      //     })
      //     reject(new Error(`Missing required field: ${field}`))
      //     return
      //   }
      // }

      // // Check for required file fields
      // for (const field of requiredFileFields) {
      //   if (!payload[field] || !(payload[field] instanceof File)) {
      //     console.error(`Missing or invalid required file field: ${field}`)
      //     Swal.fire({
      //       icon: "error",
      //       title: "Oops...",
      //       text: `Please upload the requested file: ${fieldMapping[field]}`,
      //     })
      //     reject(new Error(`Missing or invalid required file field: ${field}`))
      //     return
      //   }
      // }

      const jsonPayload = {}
      const fileData = new FormData()

      // Separate file fields from other data
      Object.keys(payload).forEach(key => {
        if (fileFields.includes(key) && payload[key] instanceof File) {
          fileData.append(key, payload[key])
        } else {
          jsonPayload[key] = payload[key]
        }
      })

      console.log('Sending POST request with JSON data')
      axios.post(`${import.meta.env.VITE_API_URL}startups/`, jsonPayload)
        .then((response) => {
          console.log('POST request successful:', response)
          const startupId = response.data.id

          console.log('Sending PATCH request with file data')
          if (fileData.entries().next().done) {
            console.log('No file data to send')
            commit('SET_FORM', null)
            dispatch('LOADER_STOP')
            return Promise.resolve(response)
          }

          axios.patch(`${import.meta.env.VITE_API_URL}startups/${startupId}/`, fileData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
            .then((response) => {
              commit('SET_FORM', null)
              console.log('PATCH request successful:', response)
              resolve(response)
            })
            .catch(error => {
              console.error('PATCH request failed:', error)
              reject(error)
            })
            .finally(() => {
              dispatch('LOADER_STOP')
            })
        })
        .catch(error => {
          console.error('Request failed:', error)
          dispatch('LOADER_STOP')
          reject(error)
        })
    })
  },

  DELETE_STARTUP ({ commit, dispatch, state }, payload) {
    dispatch('LOADER_START')
    return new Promise((resolve, reject) => {
      axios.delete(`${import.meta.env.VITE_API_URL}startups/${payload.id}`, payload)
        .then((response) => {
          dispatch('LOADER_STOP')
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  LOAD_RINGFENCE ({ commit, dispatch, state }, startupId) {
    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}ringfence/?startup_id=${startupId}`)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  UPLOAD_RINGFENCE ({ commit, dispatch, state }, payload) {
    dispatch('LOADER_START')
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      for (const [key, value] of Object.entries(payload)) {
        if (key !== 'file') {
          formData.append(key, value)
        }
      }
      if (payload.file) {
        formData.append('file', payload.file, payload.file.name)
      }
      axios.post(`${import.meta.env.VITE_API_URL}ringfence/`, formData)
        .then((response) => {
          dispatch('LOADER_STOP')
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  LOAD_STARTUP_FIELD_OPTIONS ({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      Promise.all([
        axios.get(`${import.meta.env.VITE_API_URL}options/smart-money-tags/`).then((response) => {
          commit('SET_OPTIONS_SMART_MONEY_TAGS', response.data)
        }),
        axios.get(`${import.meta.env.VITE_API_URL}options/exit-strategy/`).then((response) => {
          commit('SET_OPTIONS_EXIT_STRATEGY', response.data)
        }),
        axios.get(`${import.meta.env.VITE_API_URL}options/runway-duration/`).then((response) => {
          commit('SET_OPTIONS_RUNWAY_DURATION', response.data)
        })
      ])
        .then(resolve)
        .catch(reject)
    })
  },

  GENERATE_EMAIL_INTRO ({ commit, dispatch, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${import.meta.env.VITE_API_URL}startups/${payload.id}/generate_email_intro/`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  GENERATE_MATCH_SCORE ({ commit, dispatch, state }, { startupId, useOldMethod = false }) {
    return new Promise((resolve, reject) => {
      axios.post(`${import.meta.env.VITE_API_URL}investor_match_score/${startupId}/`, { use_old_method: useOldMethod })
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  SEARCH_TAGS ({ commit, dispatch, state }, payload) {
    let query, pageSize

    if (typeof payload === 'string') {
      query = payload
      pageSize = 10; // Default value
    } else {
      ({ query, pageSize = 10 } = payload)
    }

    return new Promise((resolve, reject) => {
      
      axios.get(`${import.meta.env.VITE_API_URL}options/tags/`, {
        params: {
          query,
          page_size: pageSize
        } })
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  GENERATE_FOLLOW_UP_EMAIL ({ commit, dispatch, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${import.meta.env.VITE_API_URL}startups/${payload.id}/generate_follow_up_email/`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  SAVE_EMAIL_DRAFT({ commit, state }, { startup_id, draft }) {
    return new Promise((resolve, reject) => {
      // Get current drafts or initialize empty array
      const currentDrafts = state.current.previous_email_drafts || []
      
      // Create new draft with timestamp
      const newDraft = {
        id: Date.now(), // Simple ID generation
        created_at: new Date().toISOString(),
        subject: draft.subject,
        content: draft.content
      }

      // Combine existing drafts with new draft
      const updatedDrafts = [...currentDrafts, newDraft]

      // Prepare payload for PATCH request
      const payload = {
        id: startup_id,
        previous_email_drafts: updatedDrafts
      }

      // Make PATCH request
      axios.patch(`${import.meta.env.VITE_API_URL}startups/${startup_id}/`, payload)
        .then((response) => {
          commit('SET_CURRENT_STARTUP', response.data)
          resolve(response)
        })
        .catch(error => {
          console.error('Failed to save email draft:', error)
          reject(error)
        })
    })
  }
}

const getters = {}

const mutations = {
  SET_STARTUPS (state, payload) {
    state.startups = payload
  },
  SET_CURRENT_STARTUP (state, payload) {
    state.current = payload
  },
  SET_FORM (state, payload) {
    state.form = payload
  },
  SET_OPTIONS_SMART_MONEY_TAGS (state, payload) {
    state.optionsSmartMoneyTags = payload
  },
  SET_OPTIONS_EXIT_STRATEGY (state, payload) {
    state.optionsExitStrategy = payload
  },
  SET_OPTIONS_RUNWAY_DURATION (state, payload) {
    state.optionsRunwayDuration = payload
  },
  CLEAR_STARTUPS (state) {
    state.startups = null
    state.current = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
