<template>
  <div>
    <div v-if="contact">
      <div class="row match-height">
        <div class="col-md-8 left-column" ref="leftColumn">
          <div class="card">
            <div class="card-body">
              <div v-if="contact && contact.has_access"
                class="d-grid gap-2 d-md-flex justify-content-md-end mb-2 float-end">
                <a href="#" class="btn btn-outline-primary" @click.prevent="goEdit">
                  <i class="uil uil-pen me-1"></i>
                  <span>Edit</span>
                </a>
                <a href="#" class="btn btn-outline-danger" @click.prevent="goDelete">
                  <i class="uil uil-trash me-1"></i>
                  <span>Delete</span>
                </a>
              </div>

              <h4 class="header-title mb-3">Contact Profile</h4>
              <span v-if="contact.unsubscribe" class="small fw-bold me-2 mb-2 " style="color:red">
                UNSUBSCRIBED
              </span>

              <div>
                <h3>
                  {{ contact.first_name }}
                  <template v-if="contact.has_access">
                    <span>{{ contact.last_name }}</span>
                  </template>
                  <template v-else>
                    <span class="blur-lg">LastName</span>
                  </template>
                  <span class="mx-2 badge text-white text-bg-dark" v-if="contact.is_good_relation">GR</span>
                  <span class="badge text-green" style="background-color: green; color: white;"
                    v-if="contact.is_deal_dashboard">DD</span>
                  <span class="badge badge-success-lighten small ms-2" v-if="contact.apollo_enriched">enriched</span>
                  <span v-if="!contact.deliverable" style="font-size:15px; color: red;">undeliverable</span>
                </h3>
              </div>

              <div class="contact-info">
                <p class="mb-2">
                  <span class="fw-bold me-2">Name:</span>
                  {{ contact.first_name }}
                  <template v-if="contact.has_access">
                    <span>{{ contact.last_name }}</span>
                  </template>
                </p>

                <p class="mb-2" v-if="contact.company">
                  <span class="fw-bold me-2">Company:</span>
                  <router-link :to="`/investors/companies/${contact.company.id}/`">
                    {{ contact.company.name }}
                  </router-link>
                </p>

                <p class="mb-2">
                  <span class="fw-bold me-2">Location:</span>
                  <span>{{ contact.location || '-' }}</span>
                </p>

                <p class="mb-2">
                  <span class="fw-bold me-2">Email:</span>
                  <template v-if="contact.has_access">
                    <span>{{ contact.email }}</span>
                  </template>
                  <template v-else>
                    <span class="blur-md">{{ contact.first_name }}@example.net</span>
                  </template>
                </p>

                <p class="mb-2">
                  <span class="fw-bold me-2">Phone Number:</span>
                  <template v-if="contact.has_access">
                    <span>{{ contact.phone_number }}</span>
                  </template>
                  <template v-else>
                    <span class="blur-md">+11234567890</span>
                  </template>
                </p>

                <p class="mb-2"><span class="fw-bold me-2">Title:</span>
                  <template v-if="contact.title && contact.title.length">
                    <span v-for="item in contact.title" :key="item">
                      <span v-if="item" class="me-2">{{ item.name }}</span>
                    </span>
                  </template>
                  <template v-else>
                    —
                  </template>
                </p>

                <p class="mb-2">
                  <span class="fw-bold me-2">LinkedIn:</span>
                  <template v-if="contact.has_access">
                    <a :href="getLinkedInURL()" v-if="contact.linkedIn_URL" target="_blank">{{ contact.linkedIn_URL
                      }}</a>
                  </template>
                  <template v-else>
                    <a class="blur-md" target="_blank">https://www.linkedin.com/in/{{ contact.first_name }}-{{
                      contact.last_name }}-{{ contact.id }}</a>
                  </template>
                </p>

                <p class="mb-2">
                  <span class="fw-bold me-2">About:</span>
                  <span>{{ contact.linkedIn_about }}</span>
                </p>

                <div class="mb-2" v-if="contact.startups.length">
                  <span>
                    <h4 class="header-title">Deals shared:</h4>
                  </span>

                  <ul>
                    <li v-for="startup in contact.startups">
                      {{ startup.name }}<br>
                      <span class="small" v-for="(campaign, index) in startup.campaigns">
                        {{ campaign }}<template v-if="index + 1 !== startup.campaigns.length">, </template>
                      </span>
                    </li>
                  </ul>
                </div>
                <span>
                  <h4 class="mt-4 header-title">Insights:</h4>
                </span>

                <div class="mb-2" v-if="contact.insights && contact.insights.length && !contact.insights_loading">
                  <div class="mb-3">
                    Filter:
                    <span class="badge badge-dark-lighten cursor-pointer ms-2" @click.prevent="this.insightType = null"
                      :class="this.insightType === null ? 'border border-dark' : ''">All</span>
                    <span class="badge badge-primary-lighten cursor-pointer ms-2"
                      @click.prevent="this.insightType = 'Webpage'"
                      :class="this.insightType === 'Webpage' ? 'border border-primary' : ''">Webpage</span>
                    <span class="badge badge-info-lighten cursor-pointer ms-2"
                      @click.prevent="this.insightType = 'LinkedIn Post'"
                      :class="this.insightType === 'LinkedIn' ? 'border border-dark' : ''">LinkedIn</span>
                    <span class="badge badge-danger-lighten cursor-pointer ms-2"
                      @click.prevent="this.insightType = 'Podcast'"
                      :class="this.insightType === 'Podcast' ? 'border border-dark' : ''">Podcast</span>
                    <span class="badge badge-dark-lighten cursor-pointer ms-2"
                      @click.prevent="this.insightType = 'Twitter'"
                      :class="this.insightType === 'Twitter' ? 'border border-dark' : ''">Twitter</span>
                  </div>
                  <div class="timeline-alt py-0" v-if="Array.isArray(contact.insights)">
                    <div class="timeline-item" v-for="item in filteredInsights">
                      <i class="mdi timeline-icon" :class="getInsightIconClasses(item)"></i>
                      <div class="timeline-item-info">
                        <a href="javascript:void(0);" class="fw-bold mb-1 d-block" :class="getInsightClass(item)">{{
                          item.title
                          }}</a>
                        {{ item.content }}<br>
                        <template v-if="item.link">
                          [via <a :href="item.link" target="_blank">{{ getDomainFromURL(item.link) }}</a>]
                        </template>
                        <p class="mb-0 pb-2">
                          <template v-if="item.type === 'Newsletter'">
                            <small class="text-muted">Email to ai@astelventures.com on {{
                              $filters.dateformat(item.timestamp) }}</small>
                          </template>
                          <template v-else>
                            <small class="text-muted" v-if="item.timestamp">{{ $filters.dateformat(item.timestamp)
                              }}</small>
                            <small class="text-muted" v-else>Recently</small>
                          </template>
                        </p>
                      </div>
                    </div>
                    <div v-if="!filteredInsights.length" class="text-muted">
                      No matching insights found yet.
                    </div>
                  </div>
                  <div v-else class="pre">
                    {{ contact.insights }}
                  </div>
                </div>

                <div v-if="contact.insights_timestamp" class="small text-muted mt-2">
                  Last updated: {{ $filters.dateformat(contact.insights_timestamp) }}
                </div>


                <br><br>

                <div class="py-2" v-if="loadingInsights || contact.insights_loading">
                  <loader size="48px"></loader>
                </div>

                <template v-if="contact.last_conversation">
                  <span>
                    <h4 class="mt-4 header-title">Last good conversation:</h4>
                  </span>

                  <div class="pre" v-html="contact.last_conversation.content"></div>
                </template>

              </div>
            </div>
          </div>
          <div class="card" v-if="contact.company">
            <div class="card-body">
              <h4 class="header-title mb-3">Company Profile</h4>

              <ul class="list-unstyled mb-0">
                <li>
                  <h3 class="mb-2">
                    {{ contact.company.name }}
                    <span v-if="contact.company.unsubscribed === 'YES'" class="small fw-bold me-2 mb-2 "
                      style="color:red">
                      UNSUBSCRIBED
                    </span>
                  </h3>

                  <p class="mb-2">
                    {{ contact.company.short_description }}
                  </p>

                  <p class="mb-2">
                    <a href="http://{{ contact.company.website }}" target="_blank">{{ contact.company.website }}</a>
                  </p>

                  <p class="mb-2">
                    <span class="fw-bold me-2">Investor location:</span>
                    <template v-if="contact.company.investor_location && contact.company.investor_location.length">
                      <span v-for="item in contact.company.investor_location" :key="item" class="d-block pre">
                        <template v-if="item">
                          <country-flag :country='item.code' size='small' />
                          {{ item.name }}
                        </template>
                      </span>
                    </template>
                    <template v-else>
                      —
                    </template>
                  </p>

                  <p class="mb-2" v-if="contact.company.investor_location_preferences">
                    <span class="fw-bold me-2">Investor Location Preferences:</span>
                    {{ contact.company.investor_location_preferences }}
                  </p>

                  <p class="mb-2">
                    <span class="fw-bold me-2">Investor cities:</span>
                    <template v-if="contact.company.investor_city && contact.company.investor_city.length">
                      <span v-for="item in contact.company.investor_city" :key="item">
                        <template v-if="item">{{ item.name }}</template>
                      </span>
                    </template>
                    <template v-else>
                      —
                    </template>
                  </p>

                  <p class="mb-2">
                    <span class="fw-bold me-2">Sectors:</span>
                    {{ contact.company.investment_sectors }}
                  </p>

                  <p class="mb-2">
                    <span class="fw-bold me-2">Stages:</span>
                    <template v-if="contact.company.investment_stages && contact.company.investment_stages.length">
                      <span v-for="stage in contact.company.investment_stages" :key="stage" class="d-block pre">
                        {{ stage.name }}
                      </span>
                    </template>
                    <template v-else>
                      —
                    </template>
                  </p>

                  <p class="mb-2">
                    <span class="fw-bold me-2">Type:</span>
                    <template v-if="contact.company.investor_type && contact.company.investor_type.length">
                      <span v-for="item in contact.company.investor_type" :key="item" class="d-block pre">
                        {{ item.name }}
                      </span>
                    </template>
                    <template v-else>
                      —
                    </template>
                  </p>

                  <p class="mb-2">
                    <span class="fw-bold me-2">Countries invested in:</span>
                    <template
                      v-if="contact.company.investment_countries && contact.company.investment_countries.length">
                      <span v-for="item in contact.company.investment_countries" :key="item" class="d-block pre">
                        <template v-if="item">
                          {{ item }}
                        </template>
                      </span>
                    </template>
                    <template v-else>
                      —
                    </template>
                  </p>

                  <p class="mb-2">
                    <span class="fw-bold me-2">Tags:</span>
                    <template
                      v-if="contact.company.industry_sector_tags && contact.company.industry_sector_tags.length">
                      <span v-for="item in contact.company.industry_sector_tags" :key="item" class="d-block pre">
                        {{ item }}
                      </span>
                    </template>
                    <template v-else>
                      —
                    </template>
                  </p>

                  <p class="mb-2" v-if="contact.company.profitable">
                    <span class="fw-bold me-2">Profitable:</span>
                    {{ contact.company.profitable }}
                  </p>

                  <p class="mb-2" v-if="contact.company.invest_in_house_technology">
                    <span class="fw-bold me-2">Invest in house technology:</span>
                    {{ contact.company.invest_in_house_technology }}
                  </p>

                  <p class="mb-2" v-if="contact.company.strategic_value">
                    <span class="fw-bold me-2">Strategic Value:</span>
                    {{ contact.company.strategic_value }}
                  </p>

                  <p class="mb-2" v-if="contact.company.revenue_preference">
                    <span class="fw-bold me-2">Revenue Preference:</span>
                    {{ contact.company.revenue_preference }}
                  </p>

                  <p class="mb-2" v-if="contact.company.investment_partners_location">
                    <span class="fw-bold me-2">Investment partners location:</span>
                    <span v-for="item in contact.company.investment_partners_location" :key="item" class="d-block pre">
                      <template v-if="item">
                        {{ item }}
                      </template>
                    </span>
                  </p>

                  <p class="mb-2" v-if="contact.company.historic_investments">
                    <span class="fw-bold me-2">Historic Investments:</span>
                    {{ $filters.money(contact.company.historic_investments) }}
                  </p>

                  <p class="mb-2" v-if="contact.company.preferred_investor_region">
                    <span class="fw-bold me-2">Preferred Investor Region:</span>
                    {{ contact.company.preferred_investor_region }}
                  </p>

                  <p class="mb-0" v-if="contact.company.profile_link">
                    <span class="fw-bold me-2">Profile Link:</span>
                    <a :href="contact.company.profile_link" target="_blank">{{ contact.company.profile_link }}</a>
                  </p>

                </li>
              </ul>

            </div>
          </div>
          <div class="card" v-if="contact && contact.has_access">
            <div class="card-body">
              <h4 class="header-title mb-3">Notes</h4>

              <div class="mb-2" v-for="(note, index) in notes">
                <template v-if="!isAddingNote && this.note.id === note.id">
                  <div class="form-group mb-3">
                    <textarea id="id_notes" type="text" name="notes" class="form-control" rows="3" required
                      v-model="note.notes">
                          </textarea>

                    <p v-if="errors.notes && errors.notes.length">
                      <span v-for="error in errors.notes" :key="error" class="text-danger">{{ error }}</span>
                    </p>
                  </div>

                  <div class="form-group mb-2">
                    <label for="id_current_investors_location">Contact Type:</label>

                    <v-select name="contact_type" v-model="note.contact_type" :options="optionsContactTypes"
                      :reduce="x => x.id" label="name" multiple>
                    </v-select>

                    <p v-if="errors.current_investors_location && errors.current_investors_location.length">
                      <span v-for="error in errors.current_investors_location" :key="error" class="text-danger">{{ error
                        }}</span>
                    </p>
                  </div>

                  <button class="btn btn-outline-primary me-3" :class="{ disabled: isSubmittingNote }"
                    @click.prevent="goCancelEditNote">
                    Cancel
                  </button>
                  <button class="btn btn-primary" :class="{ disabled: isSubmittingNote }" @click.prevent="submitNote">
                    Save Changes
                  </button>
                </template>
                <template v-else>
                  <p class="mb-1">{{ note.notes }}</p>

                  <figcaption>
                    {{ note.created_by.first_name }} {{ note.created_by.last_name }}
                    -
                    <span v-for="(type, index) in note.contact_type" :key="index">
                      <i class="uil uil-user" v-if="type.name === 'Met In Person'"></i>
                      <i class="uil uil-phone" v-if="type.name === 'Call'"></i>
                      <i class="uil uil-envelope" v-if="type.name === 'Email'"></i>
                      {{ type.name }}<template v-if="index + 1 !== note.contact_type.length">, </template>
                    </span>
                    - {{ note.created_date }}
                    -
                    <cite>
                      <a href="#" class="uil uil-pen me-1" @click.prevent="goEditNote(note)"></a>
                      <a href="#" class="ui uil-trash-alt me-1" @click.prevent="goDeleteNote(note)"></a>
                      <b v-if="index + 1 === notes.length">(Last Met)</b>
                    </cite>
                  </figcaption>
                </template>

                <hr class="col-sm-6 col-12">
              </div>

              <div class="col-sm-6 col-12" v-if="isAddingNote">
                <h5>Add Note:</h5>

                <div class="form-group mb-3">
                  <textarea id="id_notes" type="text" name="notes" class="form-control" rows="3" required
                    v-model="note.notes">
        </textarea>

                  <p v-if="errors.notes && errors.notes.length">
                    <span v-for="error in errors.notes" :key="error" class="text-danger">{{ error }}</span>
                  </p>
                </div>

                <div class="form-group mb-2">
                  <label for="id_current_investors_location">Contact Type:</label>

                  <v-select name="contact_type" v-model="note.contact_type" :options="optionsContactTypes"
                    :reduce="x => x.id" label="name" multiple>
                  </v-select>

                  <p v-if="errors.current_investors_location && errors.current_investors_location.length">
                    <span v-for="error in errors.current_investors_location" :key="error" class="text-danger">{{ error
                      }}</span>
                  </p>
                </div>

                <button class="btn btn-outline-primary me-3" :class="{ disabled: isSubmittingNote }"
                  @click.prevent="isAddingNote = false">
                  Cancel
                </button>
                <button class="btn btn-primary" :class="{ disabled: isSubmittingNote }" @click.prevent="submitNote">
                  Add Note
                </button>
              </div>

              <a v-if="!isAddingNote" href="#" class="btn btn-outline-primary" @click.prevent="goAddNote">
                <span>Add Note</span>
              </a>
              <br><br>
              <div class="form-group mb-2">
                <p class="mb-2">
                  <span class="fw-bold me-2">Engagement Call Info:</span>
                  <router-link :to="`/engagementcallinfo/${engagementCallId}/`">
                    {{ contact.first_name }} {{ contact.last_name }}
                  </router-link>
                </p>
              </div>

            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="card h-100">
            <div class="card-body career-highlights-scroll" :style="{ maxHeight: leftColumnHeight + 'px' }">
              <div v-if="contact.distance_matches && contact.distance_matches.length" class="mb-4">
                <h4 class="header-title mb-2">IN-PERSON MEETING POSSIBLE WITH</h4>
                <li v-for="match in contact.distance_matches">
                  <router-link :to="`/contacts/${match.id}/`">{{ match.name }}</router-link>
                  <span class="small ms-1" v-if="match.distance && match.distance > 0">({{ $filters.round(match.distance, 1) }} km)</span>
                </li>
              </div>
              
              <h4 class="header-title mb-3">Career Highlights</h4>

              <div class="timeline-content">
                <div class="timeline-alt py-0" v-if="contact.work_experience && contact.work_experience.length">
                  <div class="timeline-item" v-for="item in contact.work_experience">
                    <i class="mdi timeline-icon text-primary bg-primary-lighten mdi-account-tie"></i>
                    <div class="timeline-item-info">
                      <div class="mb-1">
                        {{ item.position }} at <a href="javascript:void(0);" class="fw-bold d-block text-primary">{{
                          item.company
                          }}</a>
                        <span class="small" v-if="item.details">{{ item.details }}</span>
                        <p class="mb-2">
                          <span class="fw-bold me-2">Tags:</span>
                          <template v-if="item.industry_sector_tags && item.industry_sector_tags.length">
                            <span v-for="tag in item.industry_sector_tags" :key="tag" class="d-block pre">
                              {{ tag }}
                            </span>
                          </template>
                          <template v-else>
                            —
                          </template>
                        </p>
                      </div>
                      {{ $filters.yearformat(item.start_date) }} - {{ $filters.yearformat(item.end_date) }}<br>
                      <br><br>
                    </div>
                  </div>
                </div>

                <div class="timeline-alt py-0" v-if="contact.education && contact.education.length">
                  <div class="timeline-item" v-for="item in contact.education">
                    <i class="mdi timeline-icon text-primary bg-primary-lighten mdi-school"></i>
                    <div class="timeline-item-info">
                      <div class="mb-1">
                        <span v-if="item.degree">{{ item.degree }} at </span><a href="javascript:void(0);"
                          class="fw-bold d-block text-primary">{{ item.institution }}</a>
                        <span class="small" v-if="item.details">{{ item.details }}</span>
                      </div>
                      {{ $filters.yearformat(item.start_date) }} - {{ $filters.yearformat(item.end_date) }}<br>
                      <br><br>
                    </div>
                  </div>
                </div>

                <div>
                  <button class="btn btn-outline-primary" v-if="!updatingCareer" @click.prevent="updateCareer">
                    Update Career History
                  </button>
                  <div v-else>
                    <p class="mb-0">Updating career history...</p>
                    <p>Please check back in a few minutes...</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div v-else class="text-center">
      <loader></loader>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'contact',

  data() {
    return {
      contact: null,
      engagementCallId: null,
      notes: [],
      isAddingNote: false,
      isSubmittingNote: false,
      pollTimer: null,
      updatingCareer: false,
      loadingInsights: false,
      insightType: null,
      note: {},
      errors: {},
      leftColumnHeight: 0,
    }
  },

  computed: {
    ...mapState({
      startup: state => state.startups.current,
      optionsContactTypes: state => state.investors.contactTypes
    }),
    contactId() {
      return this.$route.params.id || (this.contact ? this.contact.id : null)
    },
    filteredInsights() {
      return this.contact.insights.filter((item) => {
        if (this.insightType) {
          if (this.insightType === 'Webpage') {
            return !item.type || item.type === this.insightType
          }
          return item.type === this.insightType
        }
        return item.type !== 'Newsletter'
      })
    }
  },

  mounted() {
    window.scrollTo(0, 0)

    if (this.contactId) {
      Promise.all([
        this.$store.dispatch('LOAD_CONTACT', { contactId: this.contactId, params: { startup_id: this.startup.id } }),
        this.$store.dispatch('LOAD_CONTACT_NOTES', this.contactId),
        this.$store.dispatch('LOAD_CONTACT_TYPES')
      ])
        .then((response) => {
          this.contact = response[0].data
          this.notes = response[1].data

          if (this.contact.insights_loading) {
            this.poll()
          }
          this.fetchEngagementCallProfile(this.contact.email);
        })
    }
    this.updateHeight()
    window.addEventListener('resize', this.updateHeight)
  },

  beforeUnmount() {
    if (this.pollTimer) {
      clearTimeout(this.pollTimer)
    }
    window.removeEventListener('resize', this.updateHeight)
  },

  methods: {

    fetchEngagementCallProfile(email) {
      return this.$store.dispatch('LOAD_ENGAGEMENT_CALL_PROFILE', email)
        .then((response) => {
          this.engagementCallId = response.data.id;
        })
        .catch((error) => {
          console.error('Error fetching EngagementCallInfo ID:', error);
        });
    },

    load() {
      return this.$store.dispatch('LOAD_CONTACT', { contactId: this.contactId, params: { startup_id: this.startup.id } })
    },

    goView() {
      this.$router.push(`/contacts/${this.contact.id}`)
    },

    goEdit() {
      this.$router.push(`/contacts/${this.contact.id}/edit`)
    },

    goDelete() {
      this.$router.push(`/contacts/${this.contact.id}/delete`)
    },

    updateInsights() {
      this.loadingInsights = true
      this.$store.dispatch('UPDATE_CONTACT_INSIGHTS', this.contact)
        .then((response) => {
          this.contact = response.data
          this.poll()
        })
    },

    updateCareer() {
      this.updatingCareer = true
      this.$store.dispatch('UPDATE_CONTACT_CAREER', this.contact)
        .then((response) => {
          this.contact = response.data
        })
    },

    poll() {
      this.pollTimer = setTimeout(() => {
        this.load()
          .then((response) => {
            this.contact = response.data
            if (this.contact.insights_loading) {
              this.poll()
            } else {
              this.loadingInsights = false
            }
          })
      }, 5000)
    },

    goAddNote() {
      this.isAddingNote = true
      this.note = {
        contact: this.contactId
      }
    },

    goEditNote(note) {
      this.isAddingNote = false
      this.note = note
    },

    goCancelEditNote() {
      this.isAddingNote = false
      this.note = {}
    },

    submitNote() {
      this.isSubmittingNote = true
      this.noteErrors = {}

      const action = this.note.id ? 'UPDATE_CONTACT_NOTE' : 'CREATE_CONTACT_NOTE'

      this.$store.dispatch(action, this.note)
        .then(() => {
          this.note = {}
          this.isAddingNote = false
          this.$store.dispatch('LOAD_CONTACT_NOTES', this.contactId)
            .then((response) => {
              this.notes = response.data
            })
        })
        .catch((error) => {
          this.errors = error.response.data
        })
        .finally(() => {
          this.isSubmittingNote = false
        })
    },

    goDeleteNote(note) {
      if (confirm('Are you sure you want to delete this note?')) {
        this.$store.dispatch('DELETE_CONTACT_NOTE', note)
          .then(() => {
            this.$store.dispatch('LOAD_CONTACT_NOTES', this.contactId)
              .then((response) => {
                this.notes = response.data
              })
          })
      }
    },

    getLinkedInURL() {
      if (!this.contact || !this.contact.linkedIn_URL) return ''
      if (!this.contact.linkedIn_URL.includes('http')) {
        return `https://${this.contact.linkedIn_URL}`
      }
      return this.contact.linkedIn_URL
    },

    getDomainFromURL(url) {
      if (!url) return ''
      const domain = url.replace('http://', '').replace('https://', '').split(/[/?#]/)[0]
      return domain
    },

    getInsightTopic(item) {
      if (item.type) {
        if (item.type.includes('LinkedIn')) {
          return 'LinkedIn Post'
        }
        if (item.type.includes('Podcast')) {
          return 'Podcast'
        }
        if (item.type.includes('Twitter')) {
          return 'Twitter'
        }
      }
      return 'Webpage'
    },

    getInsightClass(item) {
      if (item.type) {
        if (item.type.includes('LinkedIn')) {
          return 'text-info'
        }
        if (item.type.includes('Podcast')) {
          return 'text-danger'
        }
        if (item.type.includes('Twitter')) {
          return 'text-primary'
        }
      }
      return 'text-default'
    },

    getInsightIconClasses(item) {
      if (item.type) {
        if (item.type.includes('LinkedIn')) {
          return 'text-info bg-info-lighten mdi-linkedin'
        }
        if (item.type.includes('Podcast')) {
          return 'text-danger bg-danger-lighten mdi-podcast'
        }
        if (item.type.includes('Twitter')) {
          return 'text-primary bg-primary-lighten mdi-twitter'
        }
      }
      return 'text-default bg-primary-lighten mdi-web'
    },

    updateHeight() {
      this.$nextTick(() => {
        if (this.$refs.leftColumn) {
          this.leftColumnHeight = this.$refs.leftColumn.offsetHeight
        }
      })
    }

  },

  watch: {
    contact: {
      handler() {
        this.$nextTick(() => {
          this.updateHeight()
        })
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.match-height {
  display: flex;
  flex-wrap: wrap;
}

.career-highlights-scroll {
  overflow-y: auto;
}

.timeline-content {
  padding-right: 10px;
  /* Add some space for the scrollbar */
}

/* Optional: Customize scrollbar appearance */
.career-highlights-scroll::-webkit-scrollbar {
  width: 6px;
}

.career-highlights-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.career-highlights-scroll::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.career-highlights-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
