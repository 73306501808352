<template>
  <div>
    <app-header></app-header>
    <div class="container-fluid">

      <div v-if="replyIoKeyState" class="row mt-3">

        <div class="col pe-0">
          <div class="card">
            <div class="card-body">

              <div class="col-md-6 col-12">

                <h3>API Key</h3>

                <div class="form-group mb-2">
                  <label for="startup_id">Select a Startup:</label>
                  <v-select
                    name="startup_id"
                    v-model="startupId"
                    :options="startups.results"
                    :reduce="x => x.id"
                    label="name"
                  >
                  </v-select>
                </div>
                
                <div v-if="startupId">
                  <p>
                    Please find the key by going to your API dashboard &gt;
                    <a href="https://run.reply.io/Dashboard/Material#/settings/apiKey" target="_blank">Settings > API Key</a>.
                  </p>

                  <div class="form-group mb-3">
                    <label for="id_reply_io_api_key">API Key:</label>
                    <input id="id_reply_io_api_key" type="text" name="reply_io_api_key" class="form-control" required v-model="form.reply_io_api_key">

                    <p v-if="errors.reply_io_api_key && errors.reply_io_api_key.length">
                      <span v-for="error in errors.reply_io_api_key" :key="error" class="text-danger">{{ error }}</span>
                    </p>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>

      </div>

      <div v-else class="text-center">
        <loader></loader>
      </div>

      <div class="action-footer">
        <div class="container-fluid">
          <div class="card mb-0">
            <div class="card-body">
              <div class="float-start">
                <button class="btn btn-outline-primary" :class="{ disabled: isSubmitting }" @click.prevent="goCancel">
                  Cancel
                </button>
              </div>
              <div class="float-end">
                <div v-if="hasError" class="text-danger me-2">Error. Please check the form.</div>
                <button class="btn btn-primary" :class="{ disabled: isSubmitting }" @click.prevent="submit">
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr class="space space-md" />
      <app-footer></app-footer>

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Header from '@/components/Layout/Header.vue'
import Footer from '@/components/Layout/Footer.vue'

export default {
  name: 'reply-io-key',

  components: {
    'app-header': Header,
    'app-footer': Footer
  },

  data() {
    return {
      startupId: null,
      form: {
        startup: null,
        reply_io_api_key: ''
      },
      errors: {},
      isSubmitting: false
    }
  },

  computed: {
    ...mapState({
      startup: state => state.startups.current,
      startups: state => state.startups.startups,
      userState: state => state.auth.user,
      replyIoKeyState: state => state.auth.replyIoKey
    }),
    hasError() {
      return Object.keys(this.errors).length > 0
    }
  },
  
  mounted() {
    window.scrollTo(0, 0)
    this.$store.dispatch('LOAD_STARTUPS')
      .then(() => {
        if (this.startup) {
          this.startupId = this.startup.id
        }
        this.load()
      })
  },

  methods: {
    load() {
      if (!this.startupId) {
        return
      }
      this.$store.dispatch('LOAD_REPLY_IO_KEY', this.startupId)
      .then(() => {
        this.form = this.replyIoKeyState
      })
    },
    submit () {
      this.isSubmitting = true
      this.errors = {}
      this.form.startup = this.startupId
      this.$store.dispatch('UPDATE_REPLY_IO_KEY', this.form)
        .then(() => {
          this.isSubmitting = false
          this.$router.go(-1)
        })
        .catch((error) => {
          this.isSubmitting = false
          this.errors = error.response.data
        })
    },
    goCancel() {
      this.$router.go(-1)
    }
  },

  watch: {
    startupId: function (val) {
      this.load()
    }
  }
}
</script>
