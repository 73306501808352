<template>
  <div class="reply-io-stats">
    <div class="stats-header">
      <div class="header-left">
        <router-link 
          to="/investors/outreach" 
          class="back-button"
        >
          <i class="el-icon-arrow-left"></i>
          <span>Back to Campaigns</span>
        </router-link>
        <h2>{{ statsData?.campaign_name || 'Campaign Statistics' }}</h2>
      </div>
      <div class="header-right">
        <div class="date-picker-wrapper">
          <span class="timezone-label">Timezone: (UTC+00:00)</span>
          <div class="date-filters">
            <div class="date-input">
              <span class="date-label">From</span>
              <el-popover
                placement="bottom-start"
                :width="300"
                trigger="click"
                v-model:visible="showStartDatePicker"
              >
                <template #reference>
                  <div class="date-value">
                    {{ formatDate(startDate) }}
                  </div>
                </template>
                <el-date-picker
                  v-model="startDate"
                  type="date"
                  format="MMM D, YYYY"
                  value-format="YYYY-MM-DD"
                  :clearable="false"
                  @change="handleDateChange"
                />
              </el-popover>
            </div>

            <div class="date-input">
              <span class="date-label">To</span>
              <el-popover
                placement="bottom-start"
                :width="300"
                trigger="click"
                v-model:visible="showEndDatePicker"
              >
                <template #reference>
                  <div class="date-value">
                    {{ formatDate(endDate) }}
                  </div>
                </template>
                <el-date-picker
                  v-model="endDate"
                  type="date"
                  format="MMM D, YYYY"
                  value-format="YYYY-MM-DD"
                  :clearable="false"
                  @change="handleDateChange"
                />
              </el-popover>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isLoading" class="loading-state">
      <el-skeleton animated>
        <template #template>
          <div class="skeleton-content">
            <!-- Metrics skeleton -->
            <div class="metrics-grid">
              <el-skeleton-item 
                v-for="n in 5" 
                :key="n"
                variant="rect" 
                class="skeleton-metric"
              />
            </div>
            <!-- Chart skeleton -->
            <el-skeleton-item 
              variant="rect" 
              class="skeleton-chart"
            />
          </div>
        </template>
      </el-skeleton>
    </div>

    <div v-else-if="error" class="error-state">
      <el-alert
        :title="error"
        type="error"
        show-icon
      />
    </div>

    <div v-else-if="statsData" class="stats-content">
      <!-- Metrics Overview -->
      <div class="metrics-grid">
        <div class="metric-card">
          <div class="metric-value">{{ totalEmails }}</div>
          <div class="metric-label">Total Emails Sent</div>
          <div class="metric-subvalue">{{ totalEmails }}</div>
        </div>
        <div class="metric-card">
          <div class="metric-value">{{ openRate }}%</div>
          <div class="metric-label">Open rate</div>
          <div class="metric-subvalue">{{ totalOpens }}</div>
        </div>
        <div class="metric-card">
          <div class="metric-value">{{ replyRate }}%</div>
          <div class="metric-label">Reply rate</div>
          <div class="metric-subvalue">{{ totalReplies }}</div>
        </div>
        <div class="metric-card">
          <div class="metric-value">{{ clickRate }}%</div>
          <div class="metric-label">Click rate</div>
          <div class="metric-subvalue">{{ totalClicks }}</div>
        </div>
        <div class="metric-card">
          <div class="metric-value">{{ bounceRate }}%</div>
          <div class="metric-label">Bounce rate</div>
          <div class="metric-subvalue">{{ totalBounces }}</div>
        </div>
      </div>

      <!-- Chart -->
      <div class="stats-chart">
        <apexchart
          type="bar"
          height="350"
          :options="chartOptions"
          :series="chartSeries"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ElPopover, ElDatePicker, ElSkeleton, ElSkeletonItem } from 'element-plus'
import { mapState } from 'vuex'
import { format, parseISO } from 'date-fns'

export default {
  name: 'ReplyIOCampaignStats',
  
  components: {
    ElPopover,
    ElDatePicker,
    ElSkeleton,
    ElSkeletonItem
  },

  data() {
    return {
      error: null,
      isLoading: false,
      startDate: format(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000), 'yyyy-MM-dd'),
      endDate: format(new Date(), 'yyyy-MM-dd'),
      showStartDatePicker: false,
      showEndDatePicker: false,
      chartOptions: {
        chart: {
          type: 'bar',
          stacked: false,
          toolbar: {
            show: false
          },
          animations: {
            enabled: true
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            borderRadius: 2,
            dataLabels: {
              position: 'top'
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          type: 'datetime',
          labels: {
            format: 'dd MMM',
            datetimeUTC: false
          }
        },
        yaxis: {
          title: {
            text: 'Count'
          }
        },
        colors: ['#4339F2', '#02BCB1', '#34B53A', '#FF8E26', '#7B61FF'],
        legend: {
          position: 'bottom',
          horizontalAlign: 'center'
        },
        tooltip: {
          enabled: true,
          shared: true,
          intersect: false,
          followCursor: true,
          onDatasetHover: {
            highlightDataSeries: true
          },
          x: {
            show: true,
            format: 'dd MMM'
          },
          y: {
            formatter: function (value) {
              return value.toFixed(0)
            }
          },
          marker: {
            show: true
          },
          fixed: {
            enabled: false
          }
        }
      }
    }
  },

  computed: {
    ...mapState({
      startup: state => state.startups.current,
      statsData: state => state.outreach.campaignStats.data
    }),

    campaignId() {
      return this.$route.params.campaignId
    },

    // Computed metrics from statsData
    totalEmails() {
      return this.statsData?.totals?.sentEmails || 0
    },
    totalOpens() {
      return this.statsData?.totals?.openedEmails || 0
    },
    totalReplies() {
      return this.statsData?.totals?.repliedEmails || 0
    },
    totalClicks() {
      return this.statsData?.totals?.clickedEmails || 0
    },
    totalBounces() {
      return this.statsData?.totals?.bouncedEmails || 0
    },

    // Rate calculations
    openRate() {
      return this.calculateRate(this.totalOpens, this.totalEmails)
    },
    replyRate() {
      return this.calculateRate(this.totalReplies, this.totalEmails)
    },
    clickRate() {
      return this.calculateRate(this.totalClicks, this.totalEmails)
    },
    bounceRate() {
      return this.calculateRate(this.totalBounces, this.totalEmails)
    },

    chartSeries() {
      if (!this.statsData?.daily_stats) return []
      
      return [
        {
          name: 'Sent',
          data: this.statsData.daily_stats.map(stat => ({
            x: new Date(stat.date).getTime(),
            y: stat.sentEmails
          }))
        },
        {
          name: 'Opens',
          data: this.statsData.daily_stats.map(stat => ({
            x: new Date(stat.date).getTime(),
            y: stat.openedEmails
          }))
        },
        {
          name: 'Replies',
          data: this.statsData.daily_stats.map(stat => ({
            x: new Date(stat.date).getTime(),
            y: stat.repliedEmails
          }))
        },
        {
          name: 'Clicks',
          data: this.statsData.daily_stats.map(stat => ({
            x: new Date(stat.date).getTime(),
            y: stat.clickedEmails
          }))
        },
        {
          name: 'Bounces',
          data: this.statsData.daily_stats.map(stat => ({
            x: new Date(stat.date).getTime(),
            y: stat.bouncedEmails
          }))
        }
      ]
    }
  },

  methods: {
    calculateRate(value, total) {
      if (!total) return 0
      return Math.round((value / total) * 100)
    },

    formatDate(date) {
      if (!date) return ''
      return format(parseISO(date), 'MMM d, yyyy')
    },

    handleDateChange() {
      if (this.startDate && this.endDate) {
        this.showStartDatePicker = false
        this.showEndDatePicker = false
        this.fetchStats()
      }
    },

    fetchStats() {
      this.error = null
      this.isLoading = true

      const payload = {
        startupId: this.startup.id,
        campaignId: this.campaignId,
        startDate: this.startDate,
        endDate: this.endDate
      }

      this.$store.dispatch('FETCH_CAMPAIGN_STATS', payload)
        .then(() => {
          // Success is handled by store mutation
        })
        .catch((error) => {
          this.error = error.response?.data?.error || 'Failed to fetch campaign stats'
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  },

  created() {
    this.fetchStats()
  }
}
</script>

<style scoped>
.reply-io-stats {
  padding: 20px;
  background-color: #f8f9fa;
}

.stats-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 16px 0;
  border-bottom: 1px solid #e9ecef;
}

.header-left {
  display: flex;
  align-items: center;
  gap: 16px;
}

.back-button {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #6b7280;
  text-decoration: none;
  font-size: 14px;
  padding: 4px 8px;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.back-button:hover {
  color: #374151;
  background-color: #f3f4f6;
}

.back-button i {
  font-size: 16px;
}

.header-left h2 {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  color: #1a1f36;
}

.header-right {
  display: flex;
  align-items: center;
  gap: 16px;
}

.date-picker-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
}

.timezone-label {
  font-size: 12px;
  color: #6b7280;
}

.date-filters {
  display: flex;
  align-items: center;
  gap: 24px;
}

.date-input {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
}

.date-label {
  font-size: 14px;
  color: #6b7280;
  font-weight: 500;
}

.date-value {
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #f3f4f6;
  color: #374151;
  font-size: 14px;
  cursor: pointer;
  min-width: 120px;
  text-align: center;
}

.date-value:hover {
  background-color: #e5e7eb;
}

.date-picker-container {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  margin-top: 4px;
}

:deep(.el-picker-panel) {
  position: static !important;
  margin-top: 4px;
  border-radius: 8px !important;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
}

:deep(.el-date-picker) {
  --el-datepicker-border-color: #e5e7eb;
  --el-datepicker-off-text-color: #9ca3af;
  --el-datepicker-header-text-color: #374151;
  --el-datepicker-icon-color: #6b7280;
  --el-datepicker-inner-border-color: #e5e7eb;
  --el-datepicker-cell-hover-color: #f3f4f6;
  --el-datepicker-active-color: #4339F2;
  width: 300px !important;
  margin: 0;
  padding: 0;
}

:deep(.el-date-picker__header) {
  margin: 8px 12px !important;
}

:deep(.el-picker-panel__content) {
  margin: 0 12px 12px !important;
}

:deep(.el-date-table th) {
  color: #6b7280 !important;
  font-weight: 500 !important;
}

:deep(.el-date-table td.current:not(.disabled) .el-date-table-cell) {
  color: white !important;
  background-color: var(--el-datepicker-active-color) !important;
}

:deep(.el-date-table td.today .el-date-table-cell) {
  color: var(--el-datepicker-active-color) !important;
  font-weight: bold !important;
}

.stats-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.metrics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
  margin-bottom: 20px;
}

.metric-card {
  background: white;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
}

.metric-value {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.metric-label {
  font-size: 14px;
  color: #666;
  margin: 4px 0;
}

.metric-subvalue {
  font-size: 12px;
  color: #999;
}

.loading-state,
.error-state {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 350px;
}

.stats-chart {
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

:deep(.el-popover) {
  padding: 0;
  border-radius: 8px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.loading-state {
  padding: 20px;
}

.skeleton-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.metrics-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px;
}

.skeleton-metric {
  height: 100px;
  border-radius: 8px;
  background-color: #f3f4f6;
}

.skeleton-chart {
  height: 350px;
  border-radius: 8px;
  background-color: #f3f4f6;
}

:deep(.el-skeleton) {
  width: 100%;
}

:deep(.el-skeleton__item) {
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
}

.error-state {
  padding: 20px;
  display: flex;
  justify-content: center;
}
</style>