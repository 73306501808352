<template>
  <div>
    <div v-if="instance">
      <div class="row">
        <div class="col pe-0">

          <div class="card">
            <div class="card-body">
              
              <div class="d-grid gap-2 d-md-flex justify-content-md-end mb-2 float-end">
                <a class="btn btn-outline-primary" @click.prevent="goEditPrompt">
                  <i class="uil uil-pen me-1"></i>
                  <span>Edit GPT Prompt</span>
                </a>

                <a href="#" class="btn btn-outline-primary" @click.prevent="goEdit">
                  <i class="uil uil-pen me-1"></i>
                  <span>Edit</span>
                </a>
                <a href="#" class="btn btn-outline-danger" @click.prevent="goDelete">
                  <i class="uil uil-trash me-1"></i>
                  <span>Delete</span>
                </a>
                <a href="#" class="btn btn-outline-info" @click.prevent="goRingfence">
                  <i class="uil uil-bag-slash me-1"></i>
                  <span>Manage Ringfence</span>
                </a>
                <a href="#" class="btn btn-outline-primary" @click.prevent="goSwitch">
                  <i class="uil uil-exchange me-1"></i>
                  <span>Switch Company</span>
                </a>
              </div>
              
              <h3>{{ instance.name }} Email Draft</h3>

              <p>Create outreach email introduction using AI.</p>

              <div v-if="isLoading">
                <loader></loader>
                <p class="my-4">Working...</p>
              </div>

              <div v-else>
                <div class="row mt-2">
                  <div class="col-12 mb-3">
                    <div class="alert alert-info">
                      <div class="d-flex">
                        <div class="me-3">
                          <i class="uil uil-info-circle h4 mb-0 text-info"></i>
                        </div>
                        <div>
                          <h5 class="text-info">Improve Your Email Templates</h5>
                          <p class="mb-2">
                            Our AI considers various factors to create personalized email templates, including:
                          </p>
                          <ul class="mb-2">
                            <li>Company details (description, valuation, raised funds)</li>
                            <li>Team information and experience</li>
                            <li>Previous successful email drafts</li>
                            <li>Investment preferences and target markets</li>
                            <li>Awards and key selling points</li>
                            <li>Pitch deck content (when available)</li>
                          </ul>
                          <p class="mb-2" v-if="!hasPreviousEmails">
                            You haven't added any previous successful email drafts yet. Adding these can help generate more effective templates.
                          </p>
                          <button class="btn btn-outline-info btn-sm" @click="goToProfile">
                            <i class="uil uil-plus-circle me-1"></i>
                            <span>Add Previous Email Drafts</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="border border-primary rounded p-3 h-100">
                      <!-- <div class="d-flex align-items-center mb-3" style="min-height: 32px;">
                        <div class="d-flex align-items-center justify-content-center" style="width: 32px; height: 32px;">
                          <i class="uil uil-envelope-alt text-primary" style="font-size: 24px;"></i>
                        </div>
                        <h5 class="mb-0 fw-bold text-primary" style="font-size: 18px; line-height: 32px;">Initial Email Template</h5>
                      </div> -->
                      <div class="d-flex align-items-center mb-3" style="min-height: 32px;">
                        <div class="d-flex align-items-center justify-content-center" style="width: 32px; height: 32px;">
                          <i class="uil uil-envelope-alt text-primary" style="font-size: 24px;"></i>
                        </div>
                        <h5 class="mb-0 fw-bold text-primary" style="font-size: 18px; line-height: 32px;">Initial Email Template</h5>
                      </div>
                      <button class="btn btn-primary mb-3" @click.prevent="goGenerateInitial">
                        <i class="uil uil-comment-alt-info me-1"></i>
                        <span>Generate Initial Email</span>
                      </button>
                      <p class="pre" v-html="intro" v-if="intro"></p>
                      <p v-else>No intro generated yet.</p>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="border border-info rounded p-3 h-100">
                      <div class="d-flex align-items-center mb-3" style="min-height: 32px;">
                        <div class="d-flex align-items-center justify-content-center" style="width: 32px; height: 32px;">
                          <i class="uil uil-envelope-redo text-info" style="font-size: 24px;"></i>
                        </div>
                        <h5 class="mb-0 fw-bold text-info" style="font-size: 18px; line-height: 32px;">Follow-up Email Template</h5>
                      </div>
                      <button class="btn btn-info mb-3" @click.prevent="goGenerateFollowUp">
                        <i class="uil uil-comment-alt-info me-1"></i>
                        <span>Generate Follow-up Email</span>
                      </button>
                      <p class="pre" v-html="follow_up_email" v-if="follow_up_email"></p>
                      <p v-else>No follow-up email generated yet.</p>
                    </div>
                  </div>
                </div>

              </div>
              
            </div>
          </div>
        </div> <!-- end col -->
      </div>

    </div>
    <div v-else class="text-center">
      <loader></loader>
    </div>

    <div class="action-footer">
      <div class="container-fluid">
        <div class="card mb-0">
          <div class="card-body">
            <div class="float-end">
              <a href="#" class="btn btn-outline-primary me-2" @click.prevent="goGenerate">
                <i class="uil uil-comment-alt-info me-1"></i>
                <span>Generate Email Intro</span>
              </a>
              <a href="#" class="btn btn-primary" @click.prevent="goMatch">
                <i class="uil uil-adjust-circle me-1"></i>
                <span>Match Investors</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import SearchMixin from '@/mixins/search'
import { mapState } from 'vuex'
import Swal from 'sweetalert2'
import CheckKeysMixin from '@/mixins/checkKeys'

export default {
  name: 'startup',

  mixins: [SearchMixin, CheckKeysMixin],

  data() {
    return {
      instance: null,
      intro: null,
      follow_up_email: null,
      isLoading: true,
    }
  },

  computed: {
    ...mapState({
      current: state => state.startups.current,
      siteCompany: state => state.auth.siteCompany,
    }),
    
    hasPreviousEmails() {
      return this.instance?.previous_email_drafts?.length > 0
    }
  },
  
  mounted() {
    window.scrollTo(0, 0)
    this.isLoading = true
    const startupId = this.$route.params.id || (this.current && this.current.id)
    if (startupId) {
      this.$store.dispatch('LOAD_STARTUP', startupId)
        .then((response) => {
          this.instance = response.data
          this.intro = this.instance.generated_email_intro || this.intro
          this.follow_up_email = this.instance.generated_follow_up_email || this.follow_up_email
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('SET_CURRENT_STARTUP', null)
          this.$router.push({ name: 'startups_selector' })
        })
        .finally(() => {
          this.isLoading = false
        })
    } else {
      this.$router.push({ name: 'startups_selector' })
    }
  },

  methods: {
    goEdit() {
      this.$router.push(`/startups/${this.instance.id}/edit`)
    },
    goDelete() {
      this.$router.push(`/startups/${this.instance.id}/delete`)
    },
    goMatch() {
      this.$router.push('/investors/contacts?match=true')
    },
    goRingfence() {
      this.$router.push(`/startups/${this.instance.id}/ringfence`)
    },
    goSwitch() {
      this.$router.push({ name: 'startups_selector' })
    },
    goGenerateInitial() {
      if(this.siteCompany.is_whitelabel){
        let task = 'generate_email_intro'
        let freeLimit = this.checkFreeLimits(task)
        if(freeLimit && freeLimit !== undefined && freeLimit !== null){
          Swal.fire({
            title: "Generate Email Intro?",
            html: `This feature requires AI usage. You have ${freeLimit} free credit/s remaining.<br><br>
                  Using this feature will consume one credit.`,
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, generate openers",
            cancelButtonText: "Cancel"
          }).then((result) => {
            if (result.isConfirmed){
              this.isLoading = true
              this.generateEmailIntro('initial')
              let limits = this.siteCompany.free_limits;
              if (task in limits && limits[task] > 0){
                limits[task] -= 1
              }
              this.updateFreeLimit(limits)
            }
          });
        } else {
          let shouldConclude = this.checkKeys()
          if (shouldConclude) {
            return
          }
          this.triggerEmailIntro('initial')
        }
      } else {
        this.triggerEmailIntro('initial')
      }
    },
    goGenerateFollowUp() {
      if(this.siteCompany.is_whitelabel){
        let task = 'generate_follow_up_email'
        let freeLimit = this.checkFreeLimits(task)
        if(freeLimit && freeLimit !== undefined && freeLimit !== null){
          Swal.fire({
            title: "Generate Follow-up Email?",
            html: `This feature requires AI usage. You have ${freeLimit} free credit/s remaining.<br><br>
                  Using this feature will consume one credit.`,
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, generate follow-up",
            cancelButtonText: "Cancel"
          }).then((result) => {
            if (result.isConfirmed){
              this.isLoading = true
              this.generateEmailIntro('follow_up')
              let limits = this.siteCompany.free_limits;
              if (task in limits && limits[task] > 0){
                limits[task] -= 1
              }
              this.updateFreeLimit(limits)
            }
          });
        } else {
          let shouldConclude = this.checkKeys()
          if (shouldConclude) {
            return
          }
          this.triggerEmailIntro('follow_up')
        }
      } else {
        this.triggerEmailIntro('follow_up')
      }
    },
    triggerEmailIntro(type){
      const typeText = type === 'initial' ? 'Initial' : 'Follow-up'
      Swal.fire({
        title: `Generate ${typeText} Email?`,
        text: "This will create a new email using AI. Please ensure you have a sufficient balance in your AI Account.",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, I have enough credits!"
      }).then((result) => {
        if (result.isConfirmed) {
          this.isLoading = true
          this.generateEmailIntro(type)
        }
      })
    },
    generateEmailIntro(type) {
      const payload = {
        id: this.instance.id,
        save: true,
        type: type
      }
      
      const action = type === 'initial' ? 'GENERATE_EMAIL_INTRO' : 'GENERATE_FOLLOW_UP_EMAIL'
      
      this.$store.dispatch(action, payload)
        .then((response) => {
          if(type === 'initial') {
            this.intro = response.data
          } else {
            this.follow_up_email = response.data
          }
          Swal.fire({
            title: "Generated!",
            text: `Your ${type === 'initial' ? 'initial' : 'follow-up'} email has been created.`,
            icon: "success"
          });
        })
        .catch((error) => {
          console.error(error)
          Swal.fire({
            title: "Error",
            text: error.response?.data?.error || "Failed to generate email.",
            icon: "error"
          });
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async goGenerate() {
      if(this.siteCompany.is_whitelabel){
        let task = 'generate_email_intro'
        let freeLimit = await this.checkFreeLimits(task)
        if(freeLimit && freeLimit !== undefined && freeLimit !== null){
          Swal.fire({
            title: "Generate Email Intro?",
            html: `This feature requires AI usage. You have ${freeLimit} free credit/s remaining.<br><br>
                  Using this feature will consume one credit.`,
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, generate openers",
            cancelButtonText: "Cancel"
          }).then((result) => {
            if (result.isConfirmed){
              this.isLoading = true
              this.generateEmailIntro()
              let limits = this.siteCompany.free_limits;
              if (task in limits && limits[task] > 0){
                limits[task] -= 1
              }
              this.updateFreeLimit(limits)
            }
          });
        } else {
          let shouldConclude = await this.checkKeys()
          if (shouldConclude) {
            return
          }
          this.triggerEmailIntro()
        }
      } else {
        this.triggerEmailIntro()
      }
    },
    goEditPrompt() {
      this.$router.push(`/me/gpt-prompts`)
    },
    goToProfile() {
      this.$router.push(`/startup/profile`)
    },
  }
}
</script>
