import axios from 'axios'

const state = {
  analysisHistory: [],
  deal: null
}

const actions = {
  LOAD_DEAL_ANALYSIS_HISTORY({ commit, dispatch }) {
    dispatch('LOADER_START')
    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}deal_analyser/`)
        .then((response) => {
          commit('SET_DEAL_ANALYSIS_HISTORY', response.data)
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
        .finally(() => {
          dispatch('LOADER_STOP')
        })
    })
  },

  FETCH_DEAL_ANALYSIS({ commit, dispatch }, id) {
    dispatch('LOADER_START')
    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}deal_analyser/${id}/`)
        .then((response) => {
          commit('SET_DEAL_ANALYSIS', response.data);
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
        .finally(() => {
          dispatch('LOADER_STOP')
        })
    })
  },

  RERUN_DEAL_ANALYSIS({ commit, dispatch }, id) {
    dispatch('LOADER_START')
    return new Promise((resolve, reject) => {
      axios.post(`${import.meta.env.VITE_API_URL}deal_analyser/${id}/rerun/`)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
        .finally(() => {
          dispatch('LOADER_STOP')
        })
    })
  },

  ADD_DEAL_ANALYSIS({ commit, dispatch }, payload) {
    dispatch('LOADER_START')
    return new Promise((resolve, reject) => {
      const fileData = new FormData()
      fileData.append('name', payload.name)
      fileData.append('pitchdeck', payload.pitchdeck)
      for (const [index, dealroomFile] of payload.dealroom_files.entries()) {
        fileData.append(`dealroom_files_${index}`, dealroomFile.file)
        fileData.append(`dealroom_files_${index}_name`, dealroomFile.name)
      }
      axios.post(`${import.meta.env.VITE_API_URL}deal_analyser/`, fileData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
        .finally(() => {
          dispatch('LOADER_STOP')
        })
    })
  }
}

const getters = {}

const mutations = {
  SET_DEAL_ANALYSIS_HISTORY(state, payload) {
    state.analysisHistory = payload
  },
  SET_DEAL_ANALYSIS(state, payload) {
    state.deal = payload
  }
}

export default {
  state,
  actions,
  mutations,
}
